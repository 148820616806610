"use client"

import { useEffect } from "react"

interface CustomErrorPageProps {
  error: Error | null
  onReset?: () => void
  isGlobalError?: boolean
}

export function CustomErrorPage({ error, onReset, isGlobalError }: CustomErrorPageProps) {
  useEffect(() => {
    // Log the error to your preferred logging service here
    console.error("Error caught by CustomErrorPage:", error)
  }, [error])


  return (
    <div className="min-h-screen flex items-center justify-center bg-BLACK">
      <div className="bg-SLATE_BLUE p-8 rounded-lg shadow-md max-w-xl w-full">
        <h1 className="text-2xl font-bold text-red-600 mb-4">Oops! Something went wrong 😅</h1>
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-4">
          <p className="text-red-700"><strong>Error:</strong> {<em>{error?.message || "An unknown error occurred"}</em>}</p>
          {!isGlobalError && <p className="text-red-700 mt-1"><strong>Global Error:</strong> Please notify the development team.</p>}
        </div>
        <p className="mb-4 text-gray-400">
          We&apos;re sorry for the inconvenience. Our team has been notified and we&apos;re working on fixing the issue.
        </p>
        <div className="bg-gray-50 p-4 rounded">
          <p className="text-sm text-gray-500 mb-2">Error details (for debugging):</p>
          <pre className="text-xs text-gray-700 overflow-auto max-h-40">
            {error?.stack || "No stack trace available"}
          </pre>
        </div>
        <button
          onClick={() => onReset ? onReset() : window.location.reload()}
          className="mt-6 w-full bg-GREEN hover:bg-GREEN-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300"
        >
          Try Again
        </button>
      </div>
    </div>
  )
}

