"use client"

import { CustomErrorPage } from "@/app/components/ui/Error/CustomErrorPage"

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  return (
    <html lang="en">
      <body className="bg-BLACK flex items-center justify-center h-dvh">
        <CustomErrorPage error={error} onReset={reset} isGlobalError={true} />
      </body>
    </html>
  )
} 

// Handles errors in the root layout and its children, but not in route segments.
// Only used in production.